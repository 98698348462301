import React from 'react'
import GridButtons from 'components/GridButtons'
import Main from 'components/main'

const Download = () => {
  return (
    <Main>
      <GridButtons />
    </Main>
  )
}

export default Download
